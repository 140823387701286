/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@ce-lib/foundation/scss/styles.scss";

body {
  background-color: $ce-light-gray;
}

.page-container {
  margin: 40px 20px 0px 20px;
}

.content-container {
  position: relative;
  width: 100%;
  min-height: 200px;
  padding: 25px;
  background-color: $ce-white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $ce-energy-blue;
  color: white;
  font-size: 20px;
  font-family: 'Lexend-Bold';
  padding: 10px 20px;
}

h4 {
  margin-bottom: 16px;
}

button:focus {
  outline: none;
}

.icon-resend,
.icon-arrow,
.icon-carrot {
  font-size: 46px;
  text-align: center;
}

.rotate-180 {
  transform: rotate(180deg);
}

.item-details-container {
  .details {
    display: grid;
    column-gap: 100px;
    row-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    margin: 40px 0px;

    &.extra-margin-top {
      margin-top: 70px !important;
    }

    &.supervisor-job {
      width: 60%;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .detail-title {
    font-family: 'OpenSans', sans-serif;
    font-size: 12px;
  }

  .detail-info {
    font-family: 'OpenSans-Semibold', sans-serif;
    font-size: 18px;

    a {
      font-family: 'OpenSans-Semibold', sans-serif;
    }
  }

  .with-link {
    display: flex;
    color: $ce-energy-blue;
  }

  a.icon-external-site {
    font-size: 30px;
    text-decoration: none;
  }
}

ce-select {
  padding: 0px !important;
}

//tablet
@media (max-width: 1023px) {
  .item-details-container {
    .details {
      column-gap: 50px;
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
}

//mobile
@media (max-width: 767px) {
  .item-details-container {
    .details {
      width: 100%;
    }

    .details,
    .details.supervisor-job {
      column-gap: 30px;
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}

// :root {
//   @include ce-theme();
// }
